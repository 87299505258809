import { BasilSearchSolid } from '@/assets/icons/comm/BasilSearchSolid';
import { EosIconsBubbleLoading } from '@/assets/icons/comm/EosIconsBubbleLoading';
import { IconParkSolidCorrect } from '@/assets/icons/comm/IconParkSolidCorrect';
import BaseInput from '@/components/base/baseInput';
import { BaseLoadingDisplay } from '@/components/base/baseLoadingDisplay';
import BaseTopNav from '@/components/base/baseTopNav';
import FeaturesSettingTopTitle from '@/components/features/featuresSettingTopTitle';
import { useModel } from '@umijs/max';
import { useMount } from 'ahooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SvgSpinnersBarsRotateFade } from '@/assets/icons/comm/SvgSpinnersBarsRotateFade';

/**
 * 国际化选语言
 * **/
export default () => {
  const { setAppLanguage, language, languageList, getLocalesReq, loading } =
    useModel('language');
  useMount(() => {
    getLocalesReq();
  });
  const { t } = useTranslation();
  const [key, setKey] = useState('');

  return (
    <div id="Language">
      <BaseTopNav
        rightNode={<>{loading && <SvgSpinnersBarsRotateFade className="mr-4" />}</>}
      />
      {/*顶部标题*/}
      <FeaturesSettingTopTitle
        title={t('国际化切换')}
        description={t('国际化切换')}
      />
      <div className="flex items-center py-[6px] px-4 w-full sticky top-0 bg-background z-50">
        <BaseInput
          className="bg-backgroundAuxiliaryColor overflow-hidden mt-2 mb-3 h-[40px]"
          value={key}
          startContent={
            <div>
              <BasilSearchSolid />
            </div>
          }
          onChange={(e) => setKey(e.target.value)}
          placeholder={t('搜索')}
          classNames={{
            base: 'rounded-md overflow-hidden h-[40px]',
            inputWrapper:
              'h-full border-none rounded-[30px]  group-data-[focus=true]:border-backgroundAuxiliaryColor',
          }}
        ></BaseInput>
      </div>

      <div className="">
        {/*语言列 表*/}
        <div>
          <BaseLoadingDisplay list={languageList}>
            {languageList
              ?.filter((item: any) => {
                if (item.name === null) return true;
                return item.name
                  .toLowerCase()
                  .includes(key.trim().toLowerCase());
              })
              ?.map((item: any) => {
                return (
                  <div
                    className={`flex  p-3 rounded-md justify-between items-center cursor-pointer`}
                    key={item.code}
                    onClick={() => {
                      setAppLanguage(item.code);
                    }}
                  >
                    <div className="flex items-center">
                      <img
                        src={item.avatar}
                        alt=""
                        className="object-cover"
                        width={20}
                        height={20}
                      />

                      <span className="ml-2 leading-[20px]">{item.name}</span>
                    </div>

                    {language === item.code && (
                      <IconParkSolidCorrect className="text-primary" />
                    )}
                  </div>
                );
              })}
          </BaseLoadingDisplay>
        </div>
      </div>
    </div>
  );
};
